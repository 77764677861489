$icon-add: '\ea01';
$icon-add_circle: '\ea02';
$icon-add_small: '\ea03';
$icon-addplayer_filled: '\ea04';
$icon-addplayer_outlined: '\ea05';
$icon-air: '\ea06';
$icon-air_pressure: '\ea07';
$icon-airplay: '\ea08';
$icon-alert_circle: '\ea09';
$icon-alert_circle_filled: '\ea0a';
$icon-alert_triangle: '\ea0b';
$icon-alert_triangle_filled: '\ea0c';
$icon-alternate_email: '\ea0d';
$icon-arrow_down: '\ea0e';
$icon-arrow_down_circle: '\ea0f';
$icon-arrow_down_ios: '\ea10';
$icon-arrow_downward: '\ea11';
$icon-arrow_drop_down: '\ea12';
$icon-arrow_drop_up: '\ea13';
$icon-arrow_insert: '\ea14';
$icon-arrow_inward: '\ea15';
$icon-arrow_left: '\ea16';
$icon-arrow_left_circle: '\ea17';
$icon-arrow_left_ios: '\ea18';
$icon-arrow_outward: '\ea19';
$icon-arrow_right: '\ea1a';
$icon-arrow_right_circle: '\ea1b';
$icon-arrow_right_ios: '\ea1c';
$icon-arrow_trending_down: '\ea1d';
$icon-arrow_trending_up: '\ea1e';
$icon-arrow_up: '\ea1f';
$icon-arrow_up_circle: '\ea20';
$icon-arrow_up_ios: '\ea21';
$icon-attach_file: '\ea22';
$icon-backspace: '\ea23';
$icon-bar_chart: '\ea24';
$icon-bird: '\ea25';
$icon-bluetooth: '\ea26';
$icon-bookmark: '\ea27';
$icon-bookmark_filled: '\ea28';
$icon-brightness: '\ea29';
$icon-calendar: '\ea2a';
$icon-camera: '\ea2b';
$icon-camera_disabled: '\ea2c';
$icon-carry: '\ea2d';
$icon-cast: '\ea2e';
$icon-check_circle: '\ea2f';
$icon-check_circle_filled: '\ea30';
$icon-checkbox: '\ea31';
$icon-checkbox_filled: '\ea32';
$icon-checkmark: '\ea33';
$icon-checkmark_thick: '\ea34';
$icon-chevron_down: '\ea35';
$icon-chevron_left: '\ea36';
$icon-chevron_right: '\ea37';
$icon-chevron_up: '\ea38';
$icon-circle_x: '\ea39';
$icon-circle_x_filled: '\ea3a';
$icon-clock: '\ea3b';
$icon-close_fullscreen: '\ea3c';
$icon-cloudy: '\ea3d';
$icon-cloudy_hail: '\ea3e';
$icon-cloudy_mix: '\ea3f';
$icon-cloudy_off: '\ea40';
$icon-cloudy_rainy: '\ea41';
$icon-cloudy_snowing: '\ea42';
$icon-cloudy_thunderstorm: '\ea43';
$icon-club_filled: '\ea44';
$icon-club_hybrid: '\ea45';
$icon-club_iron: '\ea46';
$icon-club_outlined: '\ea47';
$icon-club_wedge: '\ea48';
$icon-club_wood: '\ea49';
$icon-coach_assignments: '\ea4a';
$icon-coach_assignments_add: '\ea4b';
$icon-coach_assignments_expired: '\ea4c';
$icon-coach_custom_drill: '\ea4d';
$icon-coach_drill: '\ea4e';
$icon-coach_drill_add: '\ea4f';
$icon-code: '\ea50';
$icon-compare: '\ea51';
$icon-contrast: '\ea52';
$icon-cookie: '\ea53';
$icon-copy: '\ea54';
$icon-creditcard: '\ea55';
$icon-crop_free: '\ea56';
$icon-crosshair: '\ea57';
$icon-dispersion: '\ea58';
$icon-double_chevron_down: '\ea59';
$icon-double_chevron_left: '\ea5a';
$icon-double_chevron_right: '\ea5b';
$icon-double_chevron_up: '\ea5c';
$icon-download: '\ea5d';
$icon-edit: '\ea5e';
$icon-explore: '\ea5f';
$icon-external_link: '\ea60';
$icon-eye: '\ea61';
$icon-eye_hidden: '\ea62';
$icon-filter_filled: '\ea63';
$icon-filter_outlined: '\ea64';
$icon-flag_filled: '\ea65';
$icon-flag_outlined: '\ea66';
$icon-foggy: '\ea67';
$icon-gauge: '\ea68';
$icon-goal: '\ea69';
$icon-golf_shot: '\ea6a';
$icon-golfball: '\ea6b';
$icon-headphones: '\ea6c';
$icon-heart_filled: '\ea6d';
$icon-heart_outlined: '\ea6e';
$icon-height: '\ea6f';
$icon-help: '\ea70';
$icon-help_filled: '\ea71';
$icon-hourglass: '\ea72';
$icon-house_filled: '\ea73';
$icon-house_outlined: '\ea74';
$icon-humidity: '\ea75';
$icon-images: '\ea76';
$icon-infinite: '\ea77';
$icon-info: '\ea78';
$icon-info_filled: '\ea79';
$icon-inventory: '\ea7a';
$icon-invite_expired_outlined: '\ea7b';
$icon-invite_expired_outlined-1: '\ea7c';
$icon-invite_resend: '\ea7d';
$icon-key: '\ea7e';
$icon-language: '\ea7f';
$icon-last_updated: '\ea80';
$icon-layer: '\ea81';
$icon-leaderboard_star: '\ea82';
$icon-leaderboard_trophy: '\ea83';
$icon-library_add_check: '\ea84';
$icon-lifebuoy: '\ea85';
$icon-lightning: '\ea86';
$icon-link: '\ea87';
$icon-link_diagonal: '\ea88';
$icon-list_bound: '\ea89';
$icon-list_bulleted: '\ea8a';
$icon-live: '\ea8b';
$icon-location_android: '\ea8c';
$icon-lock: '\ea8d';
$icon-lock_filled: '\ea8e';
$icon-lock_open: '\ea8f';
$icon-logout: '\ea90';
$icon-mail: '\ea91';
$icon-maintenance_filled: '\ea92';
$icon-maintenance_outlined: '\ea93';
$icon-map: '\ea94';
$icon-menu: '\ea95';
$icon-mic: '\ea96';
$icon-mic_off: '\ea97';
$icon-minus: '\ea98';
$icon-minus_small: '\ea99';
$icon-mist: '\ea9a';
$icon-mode_dark: '\ea9b';
$icon-mode_light: '\ea9c';
$icon-monitor: '\ea9d';
$icon-more_horizontal: '\ea9e';
$icon-more_vert: '\ea9f';
$icon-navigation: '\eaa0';
$icon-new: '\eaa1';
$icon-new_three: '\eaa2';
$icon-nights_stay: '\eaa3';
$icon-notification_filled: '\eaa4';
$icon-notification_off_outlined: '\eaa5';
$icon-notification_outlined: '\eaa6';
$icon-numbers: '\eaa7';
$icon-open_fullscreen: '\eaa8';
$icon-participants_filled: '\eaa9';
$icon-participants_outlined: '\eaaa';
$icon-partly_cloudy_day: '\eaab';
$icon-pause_circle: '\eaac';
$icon-pause_filled: '\eaad';
$icon-pause_outlined: '\eaae';
$icon-percentage: '\eaaf';
$icon-pie_chart: '\eab0';
$icon-pin_filled: '\eab1';
$icon-pin_outlined: '\eab2';
$icon-play_arrow_filled: '\eab3';
$icon-play_arrow_outlined: '\eab4';
$icon-play_circle: '\eab5';
$icon-player_card: '\eab6';
$icon-power: '\eab7';
$icon-price_star: '\eab8';
$icon-pricetag: '\eab9';
$icon-profile_filled: '\eaba';
$icon-profile_outlined: '\eabb';
$icon-profile_rounded: '\eabc';
$icon-profile_rounded_filled: '\eabd';
$icon-qr_code_thick: '\eabe';
$icon-radiobutton: '\eabf';
$icon-radiobutton_filled: '\eac0';
$icon-range: '\eac1';
$icon-refresh: '\eac2';
$icon-remove_shot: '\eac3';
$icon-result_star: '\eac4';
$icon-result_trophy: '\eac5';
$icon-return: '\eac6';
$icon-rotate_left: '\eac7';
$icon-rotate_right: '\eac8';
$icon-ruler: '\eac9';
$icon-ruler_filled: '\eaca';
$icon-search: '\eacb';
$icon-search_error: '\eacc';
$icon-settings_filled: '\eacd';
$icon-settings_outlined: '\eace';
$icon-share_android: '\eacf';
$icon-share_ios: '\ead0';
$icon-signal_cellular_max: '\ead1';
$icon-skip_back: '\ead2';
$icon-skip_next: '\ead3';
$icon-sliders_horizontal: '\ead4';
$icon-sliders_vertical: '\ead5';
$icon-smartphone: '\ead6';
$icon-sort: '\ead7';
$icon-speaker: '\ead8';
$icon-speed: '\ead9';
$icon-stance: '\eada';
$icon-star_filled: '\eadb';
$icon-star_halffilled: '\eadc';
$icon-star_outlined: '\eadd';
$icon-stop_filled: '\eade';
$icon-stop_outlined: '\eadf';
$icon-sunny_filled: '\eae0';
$icon-sunny_outlined: '\eae1';
$icon-sunny_snowing: '\eae2';
$icon-support: '\eae3';
$icon-support_filled: '\eae4';
$icon-swap_horizontal: '\eae5';
$icon-swap_vertical: '\eae6';
$icon-tablet: '\eae7';
$icon-target: '\eae8';
$icon-tee_shot_filled: '\eae9';
$icon-tee_shot_outlined: '\eaea';
$icon-thermometer: '\eaeb';
$icon-thumb_up: '\eaec';
$icon-tool_wrench: '\eaed';
$icon-total_distance: '\eaee';
$icon-trashcan_blank: '\eaef';
$icon-trashcan_detailed: '\eaf0';
$icon-trophy_filled: '\eaf1';
$icon-trophy_outlined: '\eaf2';
$icon-turn_camera: '\eaf3';
$icon-unfold_ios: '\eaf4';
$icon-unlink: '\eaf5';
$icon-upload: '\eaf6';
$icon-video_cam: '\eaf7';
$icon-view_grid: '\eaf8';
$icon-width: '\eaf9';
$icon-wifi: '\eafa';
$icon-wifi_error: '\eafb';
$icon-wifi_error_android: '\eafc';
$icon-x: '\eafd';
$icon-zoom_in: '\eafe';
$icon-zoom_out: '\eaff';

$font-path: '../assets/fonts/Toptracer/';

@font-face {
  font-family: 'toptracer';
  src: url('#{$font-path}toptracer.eot');
  src:
    url('#{$font-path}toptracer.eot?grbz0t#iefix') format('embedded-opentype'),
    url('#{$font-path}toptracer.woff2?grbz0t') format('woff2'),
    url('#{$font-path}toptracer.woff?grbz0t') format('woff'),
    url('#{$font-path}toptracer.ttf?grbz0t') format('truetype'),
    url('#{$font-path}toptracer.svg?grbz0t#toptracer') format('svg');
  font-style: normal;
  font-weight: 400;
}

%icon {
  display: inline-block;
  font-family: 'toptracer' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  vertical-align: -0.125em;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon {
  @extend %icon;
}

.icon-add::before {
  content: '\ea01';
}
.icon-add_circle::before {
  content: '\ea02';
}
.icon-add_small::before {
  content: '\ea03';
}
.icon-addplayer_filled::before {
  content: '\ea04';
}
.icon-addplayer_outlined::before {
  content: '\ea05';
}
.icon-air::before {
  content: '\ea06';
}
.icon-air_pressure::before {
  content: '\ea07';
}
.icon-airplay::before {
  content: '\ea08';
}
.icon-alert_circle::before {
  content: '\ea09';
}
.icon-alert_circle_filled::before {
  content: '\ea0a';
}
.icon-alert_triangle::before {
  content: '\ea0b';
}
.icon-alert_triangle_filled::before {
  content: '\ea0c';
}
.icon-alternate_email::before {
  content: '\ea0d';
}
.icon-arrow_down::before {
  content: '\ea0e';
}
.icon-arrow_down_circle::before {
  content: '\ea0f';
}
.icon-arrow_down_ios::before {
  content: '\ea10';
}
.icon-arrow_downward::before {
  content: '\ea11';
}
.icon-arrow_drop_down::before {
  content: '\ea12';
}
.icon-arrow_drop_up::before {
  content: '\ea13';
}
.icon-arrow_insert::before {
  content: '\ea14';
}
.icon-arrow_inward::before {
  content: '\ea15';
}
.icon-arrow_left::before {
  content: '\ea16';
}
.icon-arrow_left_circle::before {
  content: '\ea17';
}
.icon-arrow_left_ios::before {
  content: '\ea18';
}
.icon-arrow_outward::before {
  content: '\ea19';
}
.icon-arrow_right::before {
  content: '\ea1a';
}
.icon-arrow_right_circle::before {
  content: '\ea1b';
}
.icon-arrow_right_ios::before {
  content: '\ea1c';
}
.icon-arrow_trending_down::before {
  content: '\ea1d';
}
.icon-arrow_trending_up::before {
  content: '\ea1e';
}
.icon-arrow_up::before {
  content: '\ea1f';
}
.icon-arrow_up_circle::before {
  content: '\ea20';
}
.icon-arrow_up_ios::before {
  content: '\ea21';
}
.icon-attach_file::before {
  content: '\ea22';
}
.icon-backspace::before {
  content: '\ea23';
}
.icon-bar_chart::before {
  content: '\ea24';
}
.icon-bird::before {
  content: '\ea25';
}
.icon-bluetooth::before {
  content: '\ea26';
}
.icon-bookmark::before {
  content: '\ea27';
}
.icon-bookmark_filled::before {
  content: '\ea28';
}
.icon-brightness::before {
  content: '\ea29';
}
.icon-calendar::before {
  content: '\ea2a';
}
.icon-camera::before {
  content: '\ea2b';
}
.icon-camera_disabled::before {
  content: '\ea2c';
}
.icon-carry::before {
  content: '\ea2d';
}
.icon-cast::before {
  content: '\ea2e';
}
.icon-check_circle::before {
  content: '\ea2f';
}
.icon-check_circle_filled::before {
  content: '\ea30';
}
.icon-checkbox::before {
  content: '\ea31';
}
.icon-checkbox_filled::before {
  content: '\ea32';
}
.icon-checkmark::before {
  content: '\ea33';
}
.icon-checkmark_thick::before {
  content: '\ea34';
}
.icon-chevron_down::before {
  content: '\ea35';
}
.icon-chevron_left::before {
  content: '\ea36';
}
.icon-chevron_right::before {
  content: '\ea37';
}
.icon-chevron_up::before {
  content: '\ea38';
}
.icon-circle_x::before {
  content: '\ea39';
}
.icon-circle_x_filled::before {
  content: '\ea3a';
}
.icon-clock::before {
  content: '\ea3b';
}
.icon-close_fullscreen::before {
  content: '\ea3c';
}
.icon-cloudy::before {
  content: '\ea3d';
}
.icon-cloudy_hail::before {
  content: '\ea3e';
}
.icon-cloudy_mix::before {
  content: '\ea3f';
}
.icon-cloudy_off::before {
  content: '\ea40';
}
.icon-cloudy_rainy::before {
  content: '\ea41';
}
.icon-cloudy_snowing::before {
  content: '\ea42';
}
.icon-cloudy_thunderstorm::before {
  content: '\ea43';
}
.icon-club_filled::before {
  content: '\ea44';
}
.icon-club_hybrid::before {
  content: '\ea45';
}
.icon-club_iron::before {
  content: '\ea46';
}
.icon-club_outlined::before {
  content: '\ea47';
}
.icon-club_wedge::before {
  content: '\ea48';
}
.icon-club_wood::before {
  content: '\ea49';
}
.icon-coach_assignments::before {
  content: '\ea4a';
}
.icon-coach_assignments_add::before {
  content: '\ea4b';
}
.icon-coach_assignments_expired::before {
  content: '\ea4c';
}
.icon-coach_custom_drill::before {
  content: '\ea4d';
}
.icon-coach_drill::before {
  content: '\ea4e';
}
.icon-coach_drill_add::before {
  content: '\ea4f';
}
.icon-code::before {
  content: '\ea50';
}
.icon-compare::before {
  content: '\ea51';
}
.icon-contrast::before {
  content: '\ea52';
}
.icon-cookie::before {
  content: '\ea53';
}
.icon-copy::before {
  content: '\ea54';
}
.icon-creditcard::before {
  content: '\ea55';
}
.icon-crop_free::before {
  content: '\ea56';
}
.icon-crosshair::before {
  content: '\ea57';
}
.icon-dispersion::before {
  content: '\ea58';
}
.icon-double_chevron_down::before {
  content: '\ea59';
}
.icon-double_chevron_left::before {
  content: '\ea5a';
}
.icon-double_chevron_right::before {
  content: '\ea5b';
}
.icon-double_chevron_up::before {
  content: '\ea5c';
}
.icon-download::before {
  content: '\ea5d';
}
.icon-edit::before {
  content: '\ea5e';
}
.icon-explore::before {
  content: '\ea5f';
}
.icon-external_link::before {
  content: '\ea60';
}
.icon-eye::before {
  content: '\ea61';
}
.icon-eye_hidden::before {
  content: '\ea62';
}
.icon-filter_filled::before {
  content: '\ea63';
}
.icon-filter_outlined::before {
  content: '\ea64';
}
.icon-flag_filled::before {
  content: '\ea65';
}
.icon-flag_outlined::before {
  content: '\ea66';
}
.icon-foggy::before {
  content: '\ea67';
}
.icon-gauge::before {
  content: '\ea68';
}
.icon-goal::before {
  content: '\ea69';
}
.icon-golf_shot::before {
  content: '\ea6a';
}
.icon-golfball::before {
  content: '\ea6b';
}
.icon-headphones::before {
  content: '\ea6c';
}
.icon-heart_filled::before {
  content: '\ea6d';
}
.icon-heart_outlined::before {
  content: '\ea6e';
}
.icon-height::before {
  content: '\ea6f';
}
.icon-help::before {
  content: '\ea70';
}
.icon-help_filled::before {
  content: '\ea71';
}
.icon-hourglass::before {
  content: '\ea72';
}
.icon-house_filled::before {
  content: '\ea73';
}
.icon-house_outlined::before {
  content: '\ea74';
}
.icon-humidity::before {
  content: '\ea75';
}
.icon-images::before {
  content: '\ea76';
}
.icon-infinite::before {
  content: '\ea77';
}
.icon-info::before {
  content: '\ea78';
}
.icon-info_filled::before {
  content: '\ea79';
}
.icon-inventory::before {
  content: '\ea7a';
}
.icon-invite_expired_outlined::before {
  content: '\ea7b';
}
.icon-invite_expired_outlined-1::before {
  content: '\ea7c';
}
.icon-invite_resend::before {
  content: '\ea7d';
}
.icon-key::before {
  content: '\ea7e';
}
.icon-language::before {
  content: '\ea7f';
}
.icon-last_updated::before {
  content: '\ea80';
}
.icon-layer::before {
  content: '\ea81';
}
.icon-leaderboard_star::before {
  content: '\ea82';
}
.icon-leaderboard_trophy::before {
  content: '\ea83';
}
.icon-library_add_check::before {
  content: '\ea84';
}
.icon-lifebuoy::before {
  content: '\ea85';
}
.icon-lightning::before {
  content: '\ea86';
}
.icon-link::before {
  content: '\ea87';
}
.icon-link_diagonal::before {
  content: '\ea88';
}
.icon-list_bound::before {
  content: '\ea89';
}
.icon-list_bulleted::before {
  content: '\ea8a';
}
.icon-live::before {
  content: '\ea8b';
}
.icon-location_android::before {
  content: '\ea8c';
}
.icon-lock::before {
  content: '\ea8d';
}
.icon-lock_filled::before {
  content: '\ea8e';
}
.icon-lock_open::before {
  content: '\ea8f';
}
.icon-logout::before {
  content: '\ea90';
}
.icon-mail::before {
  content: '\ea91';
}
.icon-maintenance_filled::before {
  content: '\ea92';
}
.icon-maintenance_outlined::before {
  content: '\ea93';
}
.icon-map::before {
  content: '\ea94';
}
.icon-menu::before {
  content: '\ea95';
}
.icon-mic::before {
  content: '\ea96';
}
.icon-mic_off::before {
  content: '\ea97';
}
.icon-minus::before {
  content: '\ea98';
}
.icon-minus_small::before {
  content: '\ea99';
}
.icon-mist::before {
  content: '\ea9a';
}
.icon-mode_dark::before {
  content: '\ea9b';
}
.icon-mode_light::before {
  content: '\ea9c';
}
.icon-monitor::before {
  content: '\ea9d';
}
.icon-more_horizontal::before {
  content: '\ea9e';
}
.icon-more_vert::before {
  content: '\ea9f';
}
.icon-navigation::before {
  content: '\eaa0';
}
.icon-new::before {
  content: '\eaa1';
}
.icon-new_three::before {
  content: '\eaa2';
}
.icon-nights_stay::before {
  content: '\eaa3';
}
.icon-notification_filled::before {
  content: '\eaa4';
}
.icon-notification_off_outlined::before {
  content: '\eaa5';
}
.icon-notification_outlined::before {
  content: '\eaa6';
}
.icon-numbers::before {
  content: '\eaa7';
}
.icon-open_fullscreen::before {
  content: '\eaa8';
}
.icon-participants_filled::before {
  content: '\eaa9';
}
.icon-participants_outlined::before {
  content: '\eaaa';
}
.icon-partly_cloudy_day::before {
  content: '\eaab';
}
.icon-pause_circle::before {
  content: '\eaac';
}
.icon-pause_filled::before {
  content: '\eaad';
}
.icon-pause_outlined::before {
  content: '\eaae';
}
.icon-percentage::before {
  content: '\eaaf';
}
.icon-pie_chart::before {
  content: '\eab0';
}
.icon-pin_filled::before {
  content: '\eab1';
}
.icon-pin_outlined::before {
  content: '\eab2';
}
.icon-play_arrow_filled::before {
  content: '\eab3';
}
.icon-play_arrow_outlined::before {
  content: '\eab4';
}
.icon-play_circle::before {
  content: '\eab5';
}
.icon-player_card::before {
  content: '\eab6';
}
.icon-power::before {
  content: '\eab7';
}
.icon-price_star::before {
  content: '\eab8';
}
.icon-pricetag::before {
  content: '\eab9';
}
.icon-profile_filled::before {
  content: '\eaba';
}
.icon-profile_outlined::before {
  content: '\eabb';
}
.icon-profile_rounded::before {
  content: '\eabc';
}
.icon-profile_rounded_filled::before {
  content: '\eabd';
}
.icon-qr_code_thick::before {
  content: '\eabe';
}
.icon-radiobutton::before {
  content: '\eabf';
}
.icon-radiobutton_filled::before {
  content: '\eac0';
}
.icon-range::before {
  content: '\eac1';
}
.icon-refresh::before {
  content: '\eac2';
}
.icon-remove_shot::before {
  content: '\eac3';
}
.icon-result_star::before {
  content: '\eac4';
}
.icon-result_trophy::before {
  content: '\eac5';
}
.icon-return::before {
  content: '\eac6';
}
.icon-rotate_left::before {
  content: '\eac7';
}
.icon-rotate_right::before {
  content: '\eac8';
}
.icon-ruler::before {
  content: '\eac9';
}
.icon-ruler_filled::before {
  content: '\eaca';
}
.icon-search::before {
  content: '\eacb';
}
.icon-search_error::before {
  content: '\eacc';
}
.icon-settings_filled::before {
  content: '\eacd';
}
.icon-settings_outlined::before {
  content: '\eace';
}
.icon-share_android::before {
  content: '\eacf';
}
.icon-share_ios::before {
  content: '\ead0';
}
.icon-signal_cellular_max::before {
  content: '\ead1';
}
.icon-skip_back::before {
  content: '\ead2';
}
.icon-skip_next::before {
  content: '\ead3';
}
.icon-sliders_horizontal::before {
  content: '\ead4';
}
.icon-sliders_vertical::before {
  content: '\ead5';
}
.icon-smartphone::before {
  content: '\ead6';
}
.icon-sort::before {
  content: '\ead7';
}
.icon-speaker::before {
  content: '\ead8';
}
.icon-speed::before {
  content: '\ead9';
}
.icon-stance::before {
  content: '\eada';
}
.icon-star_filled::before {
  content: '\eadb';
}
.icon-star_halffilled::before {
  content: '\eadc';
}
.icon-star_outlined::before {
  content: '\eadd';
}
.icon-stop_filled::before {
  content: '\eade';
}
.icon-stop_outlined::before {
  content: '\eadf';
}
.icon-sunny_filled::before {
  content: '\eae0';
}
.icon-sunny_outlined::before {
  content: '\eae1';
}
.icon-sunny_snowing::before {
  content: '\eae2';
}
.icon-support::before {
  content: '\eae3';
}
.icon-support_filled::before {
  content: '\eae4';
}
.icon-swap_horizontal::before {
  content: '\eae5';
}
.icon-swap_vertical::before {
  content: '\eae6';
}
.icon-tablet::before {
  content: '\eae7';
}
.icon-target::before {
  content: '\eae8';
}
.icon-tee_shot_filled::before {
  content: '\eae9';
}
.icon-tee_shot_outlined::before {
  content: '\eaea';
}
.icon-thermometer::before {
  content: '\eaeb';
}
.icon-thumb_up::before {
  content: '\eaec';
}
.icon-tool_wrench::before {
  content: '\eaed';
}
.icon-total_distance::before {
  content: '\eaee';
}
.icon-trashcan_blank::before {
  content: '\eaef';
}
.icon-trashcan_detailed::before {
  content: '\eaf0';
}
.icon-trophy_filled::before {
  content: '\eaf1';
}
.icon-trophy_outlined::before {
  content: '\eaf2';
}
.icon-turn_camera::before {
  content: '\eaf3';
}
.icon-unfold_ios::before {
  content: '\eaf4';
}
.icon-unlink::before {
  content: '\eaf5';
}
.icon-upload::before {
  content: '\eaf6';
}
.icon-video_cam::before {
  content: '\eaf7';
}
.icon-view_grid::before {
  content: '\eaf8';
}
.icon-width::before {
  content: '\eaf9';
}
.icon-wifi::before {
  content: '\eafa';
}
.icon-wifi_error::before {
  content: '\eafb';
}
.icon-wifi_error_android::before {
  content: '\eafc';
}
.icon-x::before {
  content: '\eafd';
}
.icon-zoom_in::before {
  content: '\eafe';
}
.icon-zoom_out::before {
  content: '\eaff';
}
