@function brighter($colorValue, $percentage: 10%) {
  @return color-mix(in srgb, $colorValue $percentage, white);
}

@function darker($colorValue, $percentage: 50%) {
  @return color-mix(in srgb, $colorValue $percentage, black);
}

@function text-stroke($color, $width: 1px) {
  @return #{$width} 0px $color, -#{$width} 0px $color, 0px #{$width} $color, 0px -#{$width} $color,
    #{$width} #{$width} $color, -#{$width} -#{$width} $color, #{$width} -#{$width} $color, -#{$width} #{$width} $color;
}

@mixin stroke($color: var(--color-background-primary), $width: 2px) {
  @supports (not (-webkit-text-stroke: #{$width} #{$color})) {
    text-shadow: text-stroke($color, calc($width / 2));
  }

  @supports (-webkit-text-stroke: #{$width} #{$color}) {
    -webkit-text-stroke: #{$width} #{$color};
    -webkit-text-fill-color: currentColor;
    paint-order: stroke fill;
  }
}

@mixin dashed-round($color, $width: 2, $radius: 16, $dash: 20, $gap: 10) {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='#{$radius}' ry='#{$radius}' stroke='%23#{$color}' stroke-width='#{$width}' stroke-dasharray='#{$dash}, #{$gap}' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: $radius * 1px;
}
