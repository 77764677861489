:root {
  // General
  --color-brand-primary: var(--color-signatur-600);
  --color-brand-secondary: var(--color-signatur-50);
  --color-brand-darker: var(--color-signatur-800);
  --color-info: var(--color-topgolf-700);
  --color-info-darker: var(--color-topgolf-800);
  --color-positive: var(--color-rainforest-600);
  --color-positive-darker: var(--color-rainforest-800);
  --color-negative: var(--color-berry-600);
  --color-negative-darker: var(--color-berry-700);
  --color-warning: var(--color-traffic-500);
  --color-warning-darker: var(--color-traffic-600);

  // Text
  --color-text-primary: var(--color-november-900);
  --color-text-secondary: var(--color-november-600);
  --color-text-tertiary: var(--color-november-500);

  --color-text-primary-reversed: var(--color-white);
  --color-text-secondary-reversed: var(--color-november-100);

  --color-text-action: var(--color-signatur-600);
  --color-text-inactive: var(--color-november-300);
  --color-text-info: var(--color-topgolf-700);
  --color-text-positive: var(--color-rainforest-700);
  --color-text-negative: var(--color-berry-700);
  --color-text-warning: var(--color-traffic-600);
  --color-text-mint: var(--color-mint-800);

  // Background
  --color-background-primary: var(--color-white);
  --color-background-secondary: var(--color-november-25);
  --color-background-tertiary: var(--color-november-50);

  --color-background-primary-reversed: var(--color-november-900);
  --color-background-secondary-reversed: var(--color-november-800);
  --color-background-tertiary-reversed: var(--color-november-700);

  // Decorative
  --color-decorative-primary: var(--color-signatur-500);
  --color-decorative-secondary: var(--color-november-50);
  --color-decorative-info: var(--color-topgolf-200);
  --color-decorative-positive: var(--color-rainforest-200);
  --color-decorative-negative: var(--color-berry-200);
  --color-decorative-warning: var(--color-traffic-100);
  --color-decorative-flamingo: var(--color-flamingo-200);
  --color-decorative-lime: var(--color-lime-200);
  --color-decorative-mint: var(--color-mint-200);
  --color-decorative-lilac: var(--color-lilac-200);

  // Accent
  --color-accent-signatur: var(--color-signatur-500);
  --color-accent-flamingo: var(--color-flamingo-500);
  --color-accent-traffic: var(--color-traffic-400);
  --color-accent-topgolf: var(--color-topgolf-500);
  --color-accent-rainforest: var(--color-rainforest-500);
  --color-accent-lilac: var(--color-lilac-500);
  --color-accent-traffic-dark: var(--color-traffic-600);
  --color-accent-mint: var(--color-mint-500);
  --color-accent-berry: var(--color-berry-500);

  // Border
  --color-border-primary: var(--color-november-50);
  --color-border-secondary: var(--color-november-25);
  --color-border-tertiary: var(--color-white);

  --color-border-primary-reversed: var(--color-november-600);

  --color-border-active: var(--color-signatur-500);
  --color-border-inactive: var(--color-november-300);
  --color-border-highlight: var(--color-november-900);
  --color-border-info: var(--color-topgolf-700);
  --color-border-positive: var(--color-rainforest-500);
  --color-border-negative: var(--color-berry-500);
  --color-border-warning: var(--color-traffic-500);

  // Hover
  --color-hover-primary: color-mix(in srgb, var(--color-november-900) 10%, transparent);
  --color-hover-action: color-mix(in srgb, var(--color-signatur-500) 10%, transparent);
  --color-hover-info: color-mix(in srgb, var(--color-topgolf-700) 10%, transparent);
  --color-hover-positive: color-mix(in srgb, var(--color-rainforest-500) 10%, transparent);
  --color-hover-negative: color-mix(in srgb, var(--color-berry-600) 10%, transparent);
  --color-hover-warning: color-mix(in srgb, var(--color-traffic-500) 10%, transparent);

  // Miscellaneous
  --color-overlay: rgba(0, 0, 0, 0.25);
  --color-shadow: rgba(0, 0, 0, 0.1);
  --color-video-slider: var(--color-november-100);
  --color-video-slider-thumb: var(--color-november-500);
}
