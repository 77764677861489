.coach-spinner {
  position: absolute;
  text-align: center;

  height: 15rem;
  width: min(100%, 30rem);

  inset: 0;
  margin: auto;

  animation: fade-in 1000ms ease-in-out forwards;

  img {
    height: 8rem;
    animation: rotate 1000ms linear infinite;
  }

  h3 {
    font-family: 'Topgolf Condensed', sans-serif;
    font-weight: 700;
    margin-top: 2rem;
    color: var(--color-text-secondary);
  }

  &.block {
    display: grid;
    grid-template-rows: 1fr;
    position: relative;
    place-items: center;
    inset: unset;

    h3 {
      display: none;
    }
  }
}
