@use 'spinner';
@use 'normalize';
@use 'reset';
@use 'font';
@use 'typography';
@use 'cdk';
@use 'icons';
@use 'colors';
@use 'variables';
@use 'caddie-corelibrary_variables';
@use 'breakpoint';
@use 'highcharts';

:root {
  @include breakpoint.tablet {
    --sidebar-width: 0rem;
  }
}

html {
  box-sizing: border-box;
  font-size: 8px;
  color-scheme: light;
  interpolate-size: allow-keywords;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

:focus-visible {
  outline-color: var(--color-text-action);
}

body {
  font-size: 2rem;
  font-family: 'ShieldSans', Arial, Helvetica, sans-serif;
  line-height: 1.5;
  letter-spacing: 0.02rem;
  color: var(--color-text-primary);
  background-color: var(--color-background-primary);
  min-height: 100dvh;
  height: 100dvh;
  min-height: -webkit-fill-available;
}

// To prevent Apple from turning our buttons blue
button,
select {
  color: var(--color-text-primary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Topgolf Condensed', sans-serif;
  font-weight: 700;
  margin: 0;
}

// TODO: Implement an outbound link component?
a {
  color: currentColor;

  &:not([href^='http']) {
    text-decoration: none;
  }
}

router-outlet {
  display: contents;
}

.text-link,
.text-link:not([href^='http']) {
  text-decoration: underline;

  &:hover,
  &:focus-visible {
    text-decoration: none;
  }
}

.content-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 3rem 8rem;
  flex: 1;
  gap: 2rem;
  margin: 0 auto;
  width: 100%;
  max-width: var(--max-wrapper-width);

  @include breakpoint.tablet {
    padding-inline: 5rem;
  }

  @include breakpoint.phone {
    padding-inline: 2rem;
  }
}

.settings-wrapper,
.admin-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.settings-wrapper {
  align-items: flex-start;
}

.has-tooltip {
  &:hover,
  &:focus-visible {
    .tooltip {
      opacity: 0.7;
    }
  }
}

.split-content {
  max-width: calc(50vw - 4rem);
}

.fullscreen .split-content {
  max-width: calc(100vw - 4rem);
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.top {
  top: initial;
  bottom: calc(100% + var(--hover-offset));
}

.bottom {
  bottom: initial;
  top: calc(100% + var(--hover-offset));
}

.right {
  right: 0;
  left: initial;
}

.left {
  left: 0;
  right: initial;
}

.staggered {
  display: block;
  opacity: 0;
  animation: pop-in-from-right 0.2s ease-in-out forwards;
}

#tab-admin,
#tab-museum {
  margin-block-start: auto;

  & + #tab-museum {
    margin-block-start: 0;
  }
}

.hide-on-tablet {
  @include breakpoint.tablet {
    display: none !important;
  }
}

.hide-on-mobile {
  @include breakpoint.phone {
    display: none !important;
  }
}

@keyframes pop-in-from-right {
  from {
    opacity: 0;
    transform: translateX(1rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes pop-in-from-top {
  from {
    opacity: 0;
    transform: translateY(1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
