:root {
  --color-november-25: #f3f4f5;
  --color-november-50: #e8e9ea;
  --color-november-100: #d0d3d5;
  --color-november-200: #b9bdc0;
  --color-november-300: #a1a7ab;
  --color-november-400: #8a9096;
  --color-november-500: #737a82;
  --color-november-600: #5b646d;
  --color-november-700: #444e58;
  --color-november-800: #2c3843;
  --color-november-900: #15222e;
  --color-november: var(--color-november-900);
  --color-white: #ffffff;
  --color-signatur-50: #ecf7fd;
  --color-signatur-100: #c5e9f8;
  --color-signatur-200: #b1e1f5;
  --color-signatur-300: #8ad2f1;
  --color-signatur-400: #63c3ec;
  --color-signatur-500: #3cb4e7;
  --color-signatur: var(--color-signatur-500);
  --color-signatur-600: #1a9ed5;
  --color-signatur-700: #157ca8;
  --color-signatur-800: #0f5b7b;
  --color-signatur-900: #0a394d;
  --color-topgolf-100: #d0e6fb;
  --color-topgolf-200: #a0ccf8;
  --color-topgolf-300: #71b3f4;
  --color-topgolf-400: #429af0;
  --color-topgolf-500: #1380ec;
  --color-topgolf-600: #0f67bd;
  --color-topgolf-700: #0b4c8c;
  --color-topgolf: var(--color-topgolf-700);
  --color-topgolf-800: #07335f;
  --color-topgolf-900: #031a30;
  --color-rainforest-50: #e9fbee;
  --color-rainforest-100: #bef4cd;
  --color-rainforest-200: #92ecab;
  --color-rainforest-300: #67e58a;
  --color-rainforest-400: #3bdd69;
  --color-rainforest-500: #22c550;
  --color-rainforest: var(--color-rainforest-500);
  --color-rainforest-600: #1a983e;
  --color-rainforest-700: #136d2c;
  --color-rainforest-800: #0b411a;
  --color-rainforest-900: #082b12;
  --color-berry-50: #f7eeed;
  --color-berry-100: #fce0de;
  --color-berry-200: #facecc;
  --color-berry-300: #f69d98;
  --color-berry-400: #f1706a;
  --color-berry-500: #ed453c;
  --color-berry: var(--color-berry-500);
  --color-berry-600: #e01f15;
  --color-berry-700: #b11910;
  --color-berry-800: #83120c;
  --color-berry-900: #540c08;
  --color-traffic-50: #fdf7e7;
  --color-traffic-100: #fcefcf;
  --color-traffic-200: #f8dfa0;
  --color-traffic-300: #f5d072;
  --color-traffic-400: #f5b657;
  --color-traffic-500: #f4b93b;
  --color-traffic: var(--color-traffic-500);
  --color-traffic-600: #c1620b;
  --color-traffic-700: #903909;
  --color-traffic-800: #602606;
  --color-traffic-900: #321301;
  --color-flamingo-100: #fdedf3;
  --color-flamingo-200: #f7bfd6;
  --color-flamingo-300: #f292b8;
  --color-flamingo-400: #ed649b;
  --color-flamingo-500: #e7367d;
  --color-flamingo: var(--color-flamingo-500);
  --color-flamingo-600: #d21963;
  --color-flamingo-700: #a4144e;
  --color-flamingo-800: #760e38;
  --color-flamingo-900: #490923;
  --color-lime-100: #f8fcf2;
  --color-lime-200: #e2f3c8;
  --color-lime-300: #ccea9e;
  --color-lime-400: #b5e174;
  --color-lime-500: #9ed849;
  --color-lime: var(--color-lime-500);
  --color-lime-600: #86c52a;
  --color-lime-700: #6a9b21;
  --color-lime-800: #4d7118;
  --color-lime-900: #31470f;
  --color-lilac-100: #eee0ff;
  --color-lilac-200: #dfc7ff;
  --color-lilac-300: #c294ff;
  --color-lilac-400: #a561ff;
  --color-lilac-500: #892eff;
  --color-lilac: var(--color-lilac-500);
  --color-lilac-600: #6c00fa;
  --color-lilac-700: #5800c7;
  --color-lilac-800: #400094;
  --color-lilac-900: #2a0061;
  --color-mint-100: #e6f9f7;
  --color-mint-200: #d2f4f1;
  --color-mint-300: #a9eae4;
  --color-mint-400: #80e0d7;
  --color-mint-500: #55d6c9;
  --color-mint: var(--color-mint-500);
  --color-mint-600: #31c9ba;
  --color-mint-700: #27a094;
  --color-mint-800: #1d776e;
  --color-mint-900: #134e48;
  --color-noir: #000000;
}
