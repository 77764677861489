@mixin font($name, $folder, $file, $weight, $style) {
  @font-face {
    font-family: $name;
    src: url('/assets/fonts/#{$folder}/#{$file}.otf') format('opentype');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

/* Shield Sans */
@include font('ShieldSans', 'ShieldSans', 'ShieldSans-Light', 300, normal);
@include font('ShieldSans', 'ShieldSans', 'ShieldSans-Regular', 400, normal);
@include font('ShieldSans', 'ShieldSans', 'ShieldSans-SemiBold', 500, normal);
@include font('ShieldSans', 'ShieldSans', 'ShieldSans-Bold', 700, normal);
@include font('ShieldSans', 'ShieldSans', 'ShieldSans-Black', 900, normal);

/* Shield Sans Condensed */
@include font('ShieldSans Condensed', 'ShieldSans', 'ShieldSans-CondensedLight', 300, normal);
@include font('ShieldSans Condensed', 'ShieldSans', 'ShieldSans-Condensed', 400, normal);
@include font('ShieldSans Condensed', 'ShieldSans', 'ShieldSans-CondensedSemiBold', 600, normal);
@include font('ShieldSans Condensed', 'ShieldSans', 'ShieldSans-CondensedBold', 700, normal);
@include font('ShieldSans Condensed', 'ShieldSans', 'ShieldSans-CondensedBlack', 900, normal);

/* Topgolf */
@include font('Topgolf', 'Topgolf', 'Topgolf-300', 300, normal);
@include font('Topgolf', 'Topgolf', 'Topgolf-500', 500, normal);
@include font('Topgolf', 'Topgolf', 'Topgolf-800', 800, normal);

/* Topgolf Italic */
@include font('Topgolf', 'Topgolf', 'Topgolf-300Italic', 300, italic);
@include font('Topgolf', 'Topgolf', 'Topgolf-500Italic', 500, italic);
@include font('Topgolf', 'Topgolf', 'Topgolf-800Italic', 800, italic);

/* Topgolf Condensed */
@include font('Topgolf Condensed', 'Topgolf', 'TopgolfCondensed-100', 100, normal);
@include font('Topgolf Condensed', 'Topgolf', 'TopgolfCondensed-300', 300, normal);
@include font('Topgolf Condensed', 'Topgolf', 'TopgolfCondensed-500', 500, normal);
@include font('Topgolf Condensed', 'Topgolf', 'TopgolfCondensed-700', 700, normal);
@include font('Topgolf Condensed', 'Topgolf', 'TopgolfCondensed-900', 900, normal);

/* Topgolf Condensed Italic */
@include font('Topgolf Condensed', 'Topgolf', 'TopgolfCondensedItalic-100', 100, italic);
@include font('Topgolf Condensed', 'Topgolf', 'TopgolfCondensedItalic-300', 300, italic);
@include font('Topgolf Condensed', 'Topgolf', 'TopgolfCondensedItalic-500', 500, italic);
@include font('Topgolf Condensed', 'Topgolf', 'TopgolfCondensedItalic-700', 700, italic);
@include font('Topgolf Condensed', 'Topgolf', 'TopgolfCondensedItalic-900', 900, italic);
