@mixin phone {
  @media screen and (max-width: 520px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 900px) {
    @content;
  }
}
