@mixin typography($font, $size, $height, $spacing, $weight: 400, $style: normal) {
  font-family: $font;
  font-size: $size;
  line-height: $height;
  letter-spacing: $spacing;
  font-style: $style;
  font-weight: $weight;
}

.display-1 {
  @include typography('Topgolf', 9.5rem, 12rem, 0rem, 800, italic);
}

.display-2 {
  @include typography('Topgolf', 6rem, 6.5rem, 0.06rem, 500);
}

.title-1 {
  @include typography('Topgolf Condensed', 4.5rem, 6rem, 0.09rem, 700);
}

.title-2 {
  @include typography('Topgolf Condensed', 3.5rem, 4.5rem, 0.035rem, 500);
}

.title-3 {
  @include typography('ShieldSans', 3rem, 4rem, 0.06rem, 700);
}

.title-4 {
  @include typography('ShieldSans', 2.5rem, 3.5rem, 0.05rem, 700);
}

.subtitle-1 {
  @include typography('ShieldSans', 2rem, 3.5rem, 0.04rem, 700);
}

.subtitle-2 {
  @include typography('ShieldSans', 2rem, 3rem, 0.02rem, 700);
}

.body-1 {
  @include typography('ShieldSans', 2rem, 3rem, 0.02rem);
}

.caption,
.caption-1 {
  @include typography('ShieldSans', 1.5rem, 2.5rem, 0.06rem);
}

.label,
.label-1 {
  @include typography('ShieldSans', 1.5rem, 1.5rem, 0.03rem);
}

.overline,
.overline-1 {
  @include typography('ShieldSans', 1.5rem, 4rem, 0.15rem);
  text-transform: uppercase;
}

.button-1 {
  @include typography('ShieldSans', 2rem, 3rem, 0.04rem, 700);
}

.button-2 {
  @include typography('ShieldSans', 2rem, 2.5rem, 0.04rem, 700);
}

.button-3 {
  @include typography('ShieldSans', 1.5rem, 2rem, 0.03rem, 700);
}
