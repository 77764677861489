:root {
  --sidebar-width: 10rem;
  --mobile-sidebar-width: 35rem;
  --top-bar-height: 8rem;
  --sub-bar-height: 6.5rem;

  --hover-offset: 0.25rem;

  --max-container-width: 100ch;
  --max-wrapper-width: 150ch;

  --spring-duration: 1000ms;
  --spring-easing: linear(
    0,
    0.0019,
    0.0076 1.21%,
    0.0334,
    0.0742,
    0.1268 5.44%,
    0.2457 8.06%,
    0.6429 15.93%,
    0.7579 18.55%,
    0.8492,
    0.9248,
    0.9848,
    1.03 28.23%,
    1.0474 29.44%,
    1.0638 30.85%,
    1.0792,
    1.0889 34.48%,
    1.0941,
    1.0944 38.51%,
    1.09 40.93%,
    1.081 43.55%,
    1.0354 53.22%,
    1.0183 57.46%,
    1.0059 61.49%,
    0.9974 65.72%,
    0.9923 70.56%,
    0.991 76.01%,
    0.9996 100%
  );

  --transition-duration: 200ms;
  --transition-easing: cubic-bezier(0.55, 0, 0.55, 0.2);
}
