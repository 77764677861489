@use 'utils';

.highcharts-yaxis-labels {
  left: 50% !important;

  > span {
    left: 0 !important;
    translate: -50%;

    > div {
      @include utils.stroke();
    }

    > .zero {
      display: none;
    }
  }
}

.highcharts-tooltip {
  .header {
    font-weight: 700;
  }
}
